import React, { useState } from "react";
import { Link, navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const MenuItem = (props) => {
  return (
    <div class="bg-secondary md:bg-white border-white border md:py-3 hover:opacity-70">
      <Link to={props.path} className="flex flex-col text-center py-3 md:py-0">
        <span className="font-medium tracking-widest whitespace-nowrap text-white md:text-primary">
          {props.title}
        </span>
        <span class="text-sm font-medium text-white md:text-secondary">
          {props.title_en}
        </span>
      </Link>
    </div>
  );
};

const DropdownMenu = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [head, ...tail] = React.Children.toArray(children);

  return (
    <div
      className="menu relative w-32"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      {head}
      {isOpen && <div className="absolute top-full w-full z-50">{tail}</div>}
    </div>
  );
};

const Item = ({ children, onClick }) => {
  return (
    <div className="relative z-10 " onClick={onClick}>
      {children}
    </div>
  );
};

const DropdownButton = ({ children }) => {
  return (
    <div
      className="py-3 w-full text-center bg-white bg-opacity-70 hover:bg-opacity-70 hover:bg-blue-100"
      style={{
        border: `solid 1px rgba(192, 192, 192, 0.7)`,
      }}
    >
      <span className="font-medium whitespace-nowrap text-white md:text-primary">
        {children}
      </span>
    </div>
  );
};

const header = () => (
  <header
    class="mx-auto md:h-28 p-0 md:py-8 md:px-5 items-center flex container"
    id="top"
  >
    <div class="w-full md:flex md:flex-row md:align-middle">
      <div class="text-center md:text-left py-8 hover:opacity-70">
        <Link to="/">
          <StaticImage src="../images/logo.png" />
        </Link>
      </div>
      <nav class="md:ml-auto md:mr-0 w-full md:w-3/4 lg:w-2/3 md:self-center">
        <ul class="list-none m-0 flex justify-between items-baseline flex-wrap md:flex-nowrap">
          <li className=" min-w-1/2 md:min-w-max">
            <MenuItem
              path="/information"
              title="ごあいさつ"
              title_en="INFORMATION"
            />
          </li>
          <li className=" min-w-1/2 md:min-w-max">
            <MenuItem path="/company" title="会社概要" title_en="COMPANY" />
          </li>
          <li className=" min-w-1/2 md:min-w-max">
            <MenuItem path="/service" title="サービス案内" title_en="SERVICE" />
          </li>
          <li className=" min-w-1/2 md:min-w-max">
            <MenuItem path="/contact" title="お問合せ" title_en="CONTACT" />
          </li>
          <li className="w-full md:max-w-min hidden md:inline-block">
            <DropdownMenu>
              <Item onClick={() => navigate("/recruit")}>
                <div className="flex flex-col text-center py-0 hover:opacity-70">
                  <span class="font-medium tracking-widest whitespace-nowrap text-white md:text-primary">
                    採用情報
                  </span>
                  <span class="text-sm font-medium text-white md:text-secondary">
                    RECRUIT
                  </span>
                </div>
              </Item>
              <Item onClick={() => navigate("/recruit")}>
                <DropdownButton>新卒採用</DropdownButton>
              </Item>
              <Item onClick={() => navigate("/recruit")}>
                <DropdownButton>キャリア採用</DropdownButton>
              </Item>
            </DropdownMenu>
          </li>

          <li class="bg-secondary md:bg-white min-w-full md:min-w-max border-white border md:py-3 hover:opacity-70 md:hidden">
            <Link
              to="/recruit"
              className="flex flex-col text-center py-3 md:py-0"
            >
              <span class="font-medium tracking-widest whitespace-nowrap text-white md:text-primary">
                採用情報
              </span>
              <span class="text-sm font-medium text-white md:text-secondary">
                RECRUIT
              </span>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  </header>
);

export default header;
