import React from "react";

const footer = () => (
  <div>
    <div class="w-full bg-secondary text-white">
      <div class="md:w-10/12 mx-auto py-2 md:py-10 text-center md:text-left">
        <div class="text-xl md:text-3xl font-medium my-3">株式会社 TAC</div>
        <div class="md:text-lg font-light my-3">
          〒601-8174 京都市南区上鳥羽清井町234番地
        </div>
        <div class="md:text-lg font-light my-3 flex flex-col md:flex-row">
          <div>TEL 075-671-1353</div>
          <span class="hidden md:inline md:mx-4">|</span>
          <div>FAX 075-671-7350</div>
          <span class="hidden md:inline md:mx-4">|</span>
          <div>Email &nbsp; info_tac@tac0314.co.jp</div>
        </div>
      </div>
    </div>
    <div class="w-full bg-primary text-white text-center text-sm font-light">
      <div class="py-2 md:py-4 flex justify-center flex-col md:flex-row">
        <div class="mx-1.5">Copyright &copy; 株式会社 TAC</div>
        <div class="mx-1.5">All rights Reserved.</div>
      </div>
    </div>
  </div>
);

export default footer;